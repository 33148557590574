import React from "react";
import * as d3 from "d3";
import rd3 from "react-d3-library";
import StringUtils from "../utilities/string-utils"
const RD3Component = rd3.Component;

export default class SparkGraph extends React.Component {

	/*
	* Props												Default
	* id - for the wrapping div.sparkgraph				randomlyGenerated
	* width	- Must be int without unit (for now)		100
	* height - Must be int without unit (for now)		50
	* yScaleMin - the minimum extent of the y axis		-1
	* yScaleMax -  the maximum extent of the y axis		1
	* data - number[] - single series only				''
	*/
	constructor(props) {
		super(props);
		this.state = {d3Node : ''} 
	}

	componentDidMount(){
		this.generateGraph();
	}

	componentDidUpdate(prevProps){
		console.log("updated")

		if(this.props.data != prevProps.data){
			this.generateGraph();
		}
	}

	generateGraph(){
		const node = document.createElement('div');

		var svg = d3.select(node)
			.append("svg")
				.attr("width", this.props.width)
				.attr("height", this.props.height)
			.append("g");

		var x = d3.scaleLinear()
			.domain([0, this.props.data.length])
			.range([0, this.props.width]);
		
		var y = d3.scaleLinear()
			.domain([this.props.yScaleMin, this.props.yScaleMax])
			.range([this.props.height, 0]);

		svg.append("path")
			.datum(this.props.data)
			.attr("fill", "none")
			.attr("stroke", "#ffdb58")
			.attr("stroke-width", 3)
			.attr("d", d3.line()
				.x(function(d, i) { return x(i) })
				.y(function(d) { return y(d) })
				.curve(d3.curveBasis)
				);


		this.setState({d3Node: node});
	}

	render() {
		return (
			<div className="sparkgraph" id={this.props.id}>
				<RD3Component data={this.state.d3Node} />
			</div>
		);
	}
}

// Setting some sensible defaults for unset props of a typical Spark chart
SparkGraph.defaultProps = {
	id: StringUtils.randomId(),
	width: 150,
	height: 50,
	yScaleMin: -1,
	yScaleMax: 1,
	data: ''
}