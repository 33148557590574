import React from "react"
import SparkGraph from "./spark"
import axios from "axios"
import NumberFormat from 'react-number-format';



export default class DataRows extends React.Component {
  state = {
    data: [],
  }

  componentDidMount() {
    var root = process.env.api

    axios.all([
      axios.get('https://api.coincap.io/v2/assets'),
      axios.get(root + "/api/v1/sentiment/cryptocurrencies?interval=PT4H&periodOrDuration=P7D"), // For the 7 day graph
      axios.get(root + "/api/v1/sentiment/cryptocurrencies?interval=PT4H&periodOrDuration=P2D") // For the 24H change
    ])
      .then(axios.spread((coincapRes, moodRes, twoDayRes) => {
        const data = coincapRes.data.data;
        var sentiment = moodRes.data;
        var twoDay = twoDayRes.data;

        sentiment = sentiment.map(function(element, i) {
          return {week: element, twoDay: twoDay[i]}
        }).reduce(function (map, obj) {

          var idxHalf = Math.round(obj.twoDay.count / 2);
          var dayPrev = obj.twoDay.sentiment.slice(0, idxHalf);
          var dayNow = obj.twoDay.sentiment.slice(idxHalf);
          var dayPrevScore = dayPrev.reduce((a, b) => a + b, 0) / dayPrev.length;
          var dayNowScore = dayNow.reduce((a, b) => a + b, 0) / dayNow.length;
          dayPrevScore = ((100 - (0)) / (1 - (-1)))*(dayPrevScore - 1) + 100
          dayNowScore = ((100 - (0)) / (1 - (-1)))*(dayNowScore - 1) + 100
          map[obj.week.topic] = {
            data: obj.week.sentiment,
            changePercent24Hr: 100 * ((dayNowScore - dayPrevScore) / dayPrevScore)
          }
          return map;
        }, {})

        this.setState({ data, sentiment });
      })
      )
  }

  render() {
    return (
      <tbody>
        {this.state.data.filter(coin => coin.id in this.state.sentiment).map(coin =>
          <tr key={coin.id}>
            <td className='d-none d-sm-table-cell'>{coin.rank}</td>
            <td>{coin.name} ({coin.symbol})</td>
            <NumberFormat
              value={coin.marketCapUsd}
              decimalScale={0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={value => <td className='d-none d-sm-table-cell'>{value}</td>} />
            <NumberFormat
              value={coin.priceUsd}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={value => <td className='d-none d-sm-table-cell'>{value}</td>} />
            {coin.changePercent24Hr >= 0 ? <NumberFormat
              value={coin.changePercent24Hr}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              suffix={'%'}
              renderText={value => <td className='d-none d-sm-table-cell'><span className="text-success">{value}</span></td>} /> :
              <NumberFormat
                value={coin.changePercent24Hr}
                decimalScale={2}
                displayType={'text'}
                thousandSeparator={true}
                suffix={'%'}
                renderText={value => <td className='d-none d-sm-table-cell'><span className="text-danger">{value}</span></td>} />
            }
            <NumberFormat
              value={((100 - (-100)) / (1 - (-1)))*((this.state.sentiment[coin.id].data.slice(-1)[0]) - 1) + 100}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              suffix={''}
              renderText={value => <td><span>{value}</span>{value >= 70 ? ' 🎉' : ''}{value < 70 && value >= 25 ? ' 😍' : ''}{value < 25 && value >= 15 ? ' 🤩' : ''}{value < 15 && value >= 14 ? ' 😁' : ''}{value < 14 && value >= 13 ? ' 😄' : ''}{value < 13 && value >= 12 ? ' 😊' : ''}{value < 12 && value >= 11 ? ' ☺' : ''}{value < 11 && value >= 10 ? ' 🙂' : ''}{value < 10 && value >= 9 ? ' 😐' : ''}{value < 9 && value >= 8 ? ' 😶' : ''}{value < 8 && value >= 5 ? ' 😕' : ''}{value < 5 && value >= 2 ? ' 🙄' : ''}{value < 2 && value >= 0 ? ' 🙁' : ''}{value < 0 && value >= -5 ? ' 😟' : ''}{value < -5 && value >= -9 ? ' 😢' : ''}{value < -9 ? ' 💩' : ''}</td>} />
            {this.state.sentiment[coin.id].changePercent24Hr >= 0 ? <NumberFormat
              value={this.state.sentiment[coin.id].changePercent24Hr}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              suffix={'%'}
              renderText={value => <td><span className="text-success">{value}</span></td>} /> :
              <NumberFormat
                value={this.state.sentiment[coin.id].changePercent24Hr}
                decimalScale={2}
                displayType={'text'}
                thousandSeparator={true}
                suffix={'%'}
                renderText={value => <td><span className="text-danger">{value}</span></td>} />
            }
            <td>
              <SparkGraph
                data={this.state.sentiment[coin.id].data}
                yScaleMin={coin.id in this.state.sentiment ? Math.min(...this.state.sentiment[coin.id].data) : -1}
                yScaleMax={coin.id in this.state.sentiment ? Math.max(...this.state.sentiment[coin.id].data) : 1} />
            </td>
          </tr>)}
      </tbody>
    )
  }
}
