import React from "react"
import { Row, Col, Container, Table } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DataRows from "../components/DataRows"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "rankings" }}>
    <SEO title="Cryptocurrency Mood Rankings" keywords={[`cryptocurrency`, `mood`, `sentiment analysis`, `bitcoin`, `rankings`]} />
    <Container className="text-center">
    <h2>Top 10 Cryptocurrencies by Market Capitalization<br />with Mood Ratings</h2>
      <Row>
        <Col>
          
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col md="12">
        <Table striped bordered hover responsive='md'>
        <thead>
          <tr>
            <th className='d-none d-sm-table-cell'>Rank</th>
            <th>Currency</th>
            <th className='d-none d-sm-table-cell'>Market Cap</th>
            <th className='d-none d-sm-table-cell'>Price</th>
            <th className='d-none d-sm-table-cell'>Price Change (24hr)</th>
            <th>Mood Rating</th>
            <th>Mood Change (24hr)</th>
            <th>Mood Graph (7 days)</th>
          </tr>
        </thead>
        <DataRows />
      </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
